import req from "@/request/request";
import API_ORDER from "@/api/Order";
import router from "@/router";

function handle(reqJson, route) {
    req.request(API_ORDER.handle, reqJson, {method: 'POST'}, {message: '处理中...'})
        .then((res) => {
            console.log(res.data)
            if (res.data.code == 200) {
                if (route) {
                    router.push(route)
                } else {
                    window.location.reload()
                }
            }
        })
}

function evaluate(reqJson) {
    req.request(API_ORDER.evaluate, reqJson, {method: 'POST'}, {message: '评价中...'})
        .then((res) => {
            if (res.data.code == 200) {
                router.push("/OrderList?active=10")
            }
        })
}

let ORDER_REQ = {
    handle,
    evaluate
}
export default ORDER_REQ