<template>
  <div class="register-select app_content">
    <div class="warp_body">

      <div>
        <van-field
            v-model="cancelObj.description"
            is-link
            readonly
            required
            name="picker"
            label="取消原因"
            placeholder="点击选择取消原因"
            @click="getList"
        />
        <van-popup v-model:show="showCancelReasonPicker" position="bottom">
          <van-picker
              :columns="cancelList"
              @confirm="onConfirmCancelReasonPicker"
              @cancel="showCancelReasonPicker = false"
          />
        </van-popup>
      </div>

      <div>
        <van-field
            v-model="cancelObj.mark"
            rows="3"
            autosize
            label="补充描述"
            type="textarea"
            maxlength="180"
            placeholder="补充描述"
            show-word-limit
        />

      </div>
      <div class="btnDiv">
        <van-button type="warning" size="large" round @click="cancelOrder">提交</van-button>
      </div>
    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API_ORDER from "../api/Order";
import req from "../request/request";
import router from "../router";
import {useRoute} from "vue-router";
import OrderReq from "@/api/OrderReq";


export default {
  name: 'CancelOrder',

  setup() {
    onBeforeMount(() => {
      const route = useRoute();
      data.cancelObj.orderId = route.params.orderId
    });
    const data = reactive({
          cancelList: [],
          showCancelReasonPicker: false,
          cancelObj: {orderId: '', type: '', description: '', mark: '', status: ''}

        },
    )

    const methodsMap = {
      onConfirmCancelReasonPicker: (item) => {
        console.log(item)
        data.cancelObj.description = item.text;
        data.cancelObj.type = item.value;
        data.showCancelReasonPicker = false;
      }, getList: () => {
        req.request(API_ORDER.cancelTypeList, null, {method: 'GET'}).then((res) => {
          data.cancelList = res.data.data
          data.showCancelReasonPicker = true
        })
      },
      cancelOrder: () => {
        console.log(data.cancelObj)
        data.cancelObj.status = JSON.parse(localStorage.getItem("userinfo")).userType.toUpperCase() == 'P'
            ? '-3' : '-2'
        OrderReq.handle(data.cancelObj, "/OrderDetail/" + data.cancelObj.orderId)
      },

    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {
    .btnDiv {
      text-align: center;
      margin-top: 40px;
      padding: 10px;
    }
  }
}
</style>
